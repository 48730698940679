import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GivenGain from "../images/gg.svg"

const DonatePage = ({ intl }) => {
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "nav.alt.donate" })}
        keywords={[`Hear The Blind Spot`, `Donate`, `GivenGain Donate!`]}
      />
      <div className="container flex flex-wrap w-full">
        <p className="donateH1 w-full">
          {intl.formatMessage({ id: "donate.p1" })}
        </p>
        <p className="bodyText w-full">
          {intl.formatMessage({ id: "donate.p2" })}
        </p>
        <p className="headingText w-full">
          {intl.formatMessage({ id: "donate.title" })}
        </p>

        <div className="py-2">
          <p className="bodyText w-full">
            {intl.formatMessage({ id: "donate.p3" })}
          </p>
        </div>

        <div className="py-4 w-full">
          <p className="donateMethod ">
            {intl.formatMessage({ id: "donate.method.first" })}
          </p>
          <div className="block mt-6">
            <a
              target="_blank"
              className="border-0"
              rel="noopener noreferrer"
              href="https://www.givengain.com/cause/15772/campaigns/19448/donate/"
            >
              <img
                src={GivenGain}
                alt={intl.formatMessage({ id: "nav.alt.givengain" })}
                className="h-16 w-auto"
              />
            </a>
          </div>
        </div>

        <div className="py-4 w-full">
          <p className="donateMethod w-full">
            {intl.formatMessage({ id: "donate.method.second" })}
          </p>
          <div className="flex flex-wrap">
            <div className="flex w-full">
              <div className="w-2/5 boldBank">
                {intl.formatMessage({ id: "account.title.name" })}
              </div>
              <div className="w-1/5">:</div>
              <div className="w-3/5 -ml-12">
                {intl.formatMessage({ id: "account.content.name" })}
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-2/5 boldBank">
                {intl.formatMessage({ id: "account.title.number" })}
              </div>
              <div className="w-1/5">:</div>
              <div className="w-3/5 -ml-12">
                {intl.formatMessage({ id: "account.content.number" })}
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-2/5 boldBank">
                {intl.formatMessage({ id: "account.title.branch" })}
              </div>
              <div className="w-1/5">:</div>
              <div className="w-3/5 -ml-12">
                {intl.formatMessage({ id: "account.content.branch" })}
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-2/5 boldBank">
                {intl.formatMessage({ id: "account.title.bank" })}
              </div>
              <div className="w-1/5">:</div>
              <div className="w-3/5 -ml-12">
                {intl.formatMessage({ id: "account.content.bank" })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(DonatePage)
